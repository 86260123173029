<nav class="navbar navbar-expand-lg fixed-top navbar-dark shadow-sm p-0">
  <div class="container-md">
    <!-- your logo here -->
    <a class="navbar-brand" href="#home">
      <img src="assets/imgs/tamil.png"
           alt="- Tamil Crush Elevate Your Dating"
           height="50">
    </a>
    <!-- mobile button navbar toggle -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- navigation links -->
    <div class="collapse navbar-collapse justify-content-end" id="navbar">
      <div class="navbar-nav align-items-center">
        <a class="nav-link" href="#home">Home</a>
        <a class="nav-link" href="#intro">How-it-works</a>
        <a class="nav-link" href="#faq">FAQ</a>
        <a class="nav-link" routerLink="/terms">Terms</a>
        <a class="nav-link" routerLink="/privacy">Privacy</a>

        <!--        <a class="nav-link" href="#reviews">reviews</a>-->
        <!--        <a class="nav-link" href="#contact">-->
        <!--          <button id="btn-nav">Date Now</button>-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</nav>
