<!-- svg start shape footer -->
<svg preserveAspectRatio="none" width="100%" class="bg-arrow" viewBox="0 0 1200 100" xmlns="http://www.w3.org/2000/svg">
  <polygon fill="#B7115EFF" points="600 0 0 100 1200 100 600 0"></polygon>
</svg>
<!-- footer -->
<footer>
  <!-- footer container -->
  <div class="container-md">
    <div class="row flex justify-content-center">
      <div class="col-md-2">
        <!-- your logo here -->
        <img src="assets/imgs/logo.png"
             alt="- Tamil Crush Elevate Your Dating" height="135">
      </div>
      <!-- footer widget -->
      <div class="col-md-10">
        <ul class="social">
          <li><a class="nav-link" routerLink="/terms">Terms Of Use</a></li>
          <li><a class="nav-link" routerLink="/privacy">Privacy Policy</a></li>
<!--          <li><a class="nav-link" routerLink="/terms">About Us</a></li>-->
        </ul>
      </div>
      <!-- footer widget -->
      <!--      <div class="col-md-3">-->
      <!--        <p><strong>Social</strong></p>-->
      <!--        &lt;!&ndash; social links &ndash;&gt;-->
      <!--        <ul class="social">-->
      <!--          &lt;!&ndash; contact item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Gmail" href="tel:">-->
      <!--              <i class="fa-solid fa-phone"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; contact item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Gmail" href="mailto:">-->
      <!--              <i class="fa-solid fa-envelope"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Whatsapp" href="#">-->
      <!--              <i class="fa-brands fa-whatsapp"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Instagram" href="#">-->
      <!--              <i class="fa-brands fa-instagram"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Twitter" href="#">-->
      <!--              <i class="fa-brands fa-twitter"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
    </div>
  </div>
  <!-- credits -->
  <div class="pt-4">&copy; Tamil Crush <span id="date"></span></div>
</footer>


<!-- scrool to top button -->
<a id="toTop" title="Torna su">
  <i class="fa-solid fa-angle-up"></i>
</a>

