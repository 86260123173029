import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent implements OnInit {
  items: MenuItem[]

  constructor() {
    this.items = []
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Tamil Crush',
      }
    ]
  }
}
